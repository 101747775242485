import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { ReactComponent as LogoSvg } from "../../images/logo.svg";

import { grid, spacing, screen, blackOrWhite } from "../../styles/mixins";
import { colours } from "../../styles/colours";
import { meta } from "../../styles/typography";
import Navigation from "./Navigation";
import { useGetResizer } from "../../contexts/Resizer";
import { AnimatePresence, motion } from "framer-motion";
import ReadProgress from "./ReadProgress";
import { Link } from "gatsby";
import { useUIState } from "../../contexts/UIStore";
import { observer } from "mobx-react-lite";
import { css } from "@emotion/react";

const Container = styled.header`
	width: 100vw;
	position: fixed;
	z-index: 100;
	pointer-events: none;
`;

const HeaderContainer = styled.header`
	${spacing("paddingTop", "m")};
	${spacing("paddingBottom", "m")};
	${grid};
	pointer-events: auto;
	width: 100%;
	--fg: ${(props) => props.theme.foreground};
	--bg: ${(props) => props.theme.background};
	color: var(--fg);
	background-color: ${({ floating }) =>
		floating ? "var(--bg)" : "transparent"};
	transform: ${({ visible }) => (visible ? "none" : "translateY(-100%)")};
	transition: transform 300ms ease-in-out, background-color 300ms ease-in-out,
		border-color 300ms ease-in-out, color 300ms ease-in-out;
	position: relative;
	border-bottom: 1px solid;
	border-bottom-color: ${({ floating, visible }) =>
		floating && visible ? "var(--fg)" : "transparent"};

	${({ locked }) => {
		if (locked) {
			return css`
				transform: none;
				background-color: var(--bg);
				border-bottom-color: var(--fg);
			`;
		}
	}}
`;

const Inner = styled.div`
	grid-column: 3 / -3;
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	align-items: center;

	@media ${screen("md")} {
		grid-column: 2 / -2;
		grid-template-columns: auto auto auto 1fr;
	}
`;

const LogoWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	z-index: 200;

	a {
		display: inline-block;
		transition: opacity 400ms ease;

		&:hover {
			opacity: 0.7;
		}
	}

	svg {
		height: 1.5em;
		max-width: 100%;
		width: auto;
		path {
			fill: ${(props) => props.theme.foreground};
			transition: fill 300ms ease-in-out;
		}
	}
`;

const Title = styled(motion.div)`
	justify-self: center;
	${meta};
	line-height: 1.4;
	${spacing("marginRight", "m")};
	width: 260px;
	overflow: hidden;
	font-weight: 400;
	will-change: opacity;
	display: none;

	@media ${screen("md")} {
		display: block;
	}
`;

const MenuButton = styled.div`
	width: 30px;
	height: 13px;
	position: relative;
`;
const MenuLine = styled.div`
	width: ${({ position = "top" }) => (position === "bottom" ? 25 : 30)}px;
	height: 2px;
	position: absolute;
	top: 0;
	right: 0;
	transform-origin: ${({ position = "top" }) =>
		position === "bottom" ? "10px 0.5px" : `15px 0.5px`};
	transition: opacity 0.3s, transform 0.3s, background-color 300ms ease-in-out;

	${({ position, navigationOpen = false }) => {
		if (position === "top") {
			if (navigationOpen) {
				return css`
					transform: translateY(6px) rotate(45deg);
				`;
			} else {
				return css`
					transform: translateY(0px);
				`;
			}
		} else if (position === "middle") {
			if (navigationOpen) {
				return css`
					transform: translateY(6px) rotate(-45deg);
				`;
			} else {
				return css`
					transform: translateY(6px);
				`;
			}
		} else {
			if (navigationOpen) {
				return css`
					transform: translateY(6px) rotate(-45deg);
				`;
			} else {
				return css`
					transform: translateY(12px);
				`;
			}
		}
	}};

	${({ navigationOpen }) => css`
		background-color: ${navigationOpen ? "white" : "var(--fg)"};
	`};
`;

const ToggleCard = styled.div`
	position: relative;
	width: 20px;
	height: 25px;
	${spacing("marginLeft", "s")};
	transform-style: preserve-3d;
	transition: transform 800ms ease;
`;

const CardBase = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	border-radius: 3px;
	transition: background 400ms ease;
	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		transition: stroke 400ms ease;
		transform: translateX(-50%) translateY(-50%);
	}
`;

const CardFront = styled(CardBase)``;
const CardBack = styled(CardBase)`
	transform: translateZ(1px);
`;

const NavigationToggle = styled.div`
	justify-self: end;
	${meta};
	cursor: pointer;
	display: flex;
	align-items: center;
	transition: color 400ms ease;
	font-weight: 600;
	position: relative;
	z-index: 200;
	color: ${(props) => props.navigationOpen && "black"};

	${CardFront}, ${CardBack} {
		background: ${(props) =>
			props.navigationOpen ? "black" : props.theme.foreground};
	}

	${ToggleCard} {
		${(props) => props.navigationOpen && "transform: rotateY(180deg);"}
	}

	svg {
		path {
			stroke: ${(props) =>
				props.navigationOpen ? "white" : props.theme.background};
		}
	}

	&:hover {
		color: ${colours.highlight};

		&::after {
			transform: scaleX(1);
		}

		${CardBack}, ${CardFront} {
			background: ${colours.highlight} !important;
		}

		svg path {
			stroke: black;
		}
	}
`;

const MainTitle = styled.div`
	width: 160px;
	${meta};
	line-height: 1.4;
	font-feature-settings: "salt" on;
	font-weight: 600;
	${spacing("marginLeft", "m")};
	${spacing("marginRight", "l")};
	display: none;
	@media ${screen("md")} {
		display: block;
	}
`;

const AnimatedSectionTitle = observer(() => {
	const uiState = useUIState();
	return (
		<AnimatePresence exitBeforeEnter>
			<Title
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				dangerouslySetInnerHTML={{ __html: uiState.section.toUpperCase() }}
				key={uiState.section}
			/>
		</AnimatePresence>
	);
});

const Header = observer(() => {
	const [navigationOpen, setNavigationOpen] = useState(false);
	const ref = useRef();
	const area = useGetResizer();
	const scrollPos = useRef(0);
	const isScrollingUp = useRef(false);
	const scrollPosOnUpStart = useRef(0);
	const [visible, setVisible] = useState(true);
	const [floating, setFloating] = useState(false);
	const threshholdToVisible = 70; // amount (px) at top of page where header always visible
	const threshholdAlwaysVisible = 50; // amount (px) user must scroll up to make header visible
	const [windowHeight, setWindowHeight] = useState(768);
	const uiState = useUIState();
	const theme = uiState.theme;

	useEffect(() => {
		let rect = ref.current.getBoundingClientRect();
		uiState.setNavHeight(rect.height);
	}, [area, uiState]);

	useEffect(() => {
		const handleResize = () => {
			updateWindowHeight();
		};

		const updateWindowHeight = () => {
			setWindowHeight(window.innerHeight);
		};

		updateWindowHeight();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (uiState.lockNav) return;

		const handleScroll = () => {
			const currentScrollPos = window.scrollY;
			const prevScrollPos = scrollPos.current;
			scrollPos.current = currentScrollPos;
			const direction = currentScrollPos - prevScrollPos;

			if (!isScrollingUp.current && direction < 0) {
				// if started scrolling up
				isScrollingUp.current = true;
				scrollPosOnUpStart.current = currentScrollPos;
			} else if (direction >= 0) {
				// if scroll direction is down
				isScrollingUp.current = false;
				setVisible(false);
				uiState.setNavOpen(false);
			}

			const amountScrolledUp = scrollPosOnUpStart.current - currentScrollPos;

			if (
				(isScrollingUp.current && amountScrolledUp > threshholdToVisible) ||
				currentScrollPos < threshholdAlwaysVisible
			) {
				setVisible(true);
				uiState.setNavOpen(true);
			} else {
				setVisible(false);
				uiState.setNavOpen(false);
			}

			setFloating(currentScrollPos >= windowHeight);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [windowHeight, uiState]);

	const handleNavigationToggle = () => {
		setNavigationOpen(!navigationOpen);
	};

	return (
		<Container ref={ref}>
			<ReadProgress />
			<Navigation open={navigationOpen} handleToggle={handleNavigationToggle} />
			<HeaderContainer
				theme={theme}
				visible={visible}
				floating={floating}
				locked={uiState.lockNav}>
				<Inner>
					<LogoWrapper theme={theme} navigationOpen={navigationOpen}>
						<Link to="/">
							<LogoSvg />
						</Link>
					</LogoWrapper>
					<MainTitle>SOCIAL MOBILITY IN THE DIGITAL AGE</MainTitle>
					<AnimatedSectionTitle />
					<NavigationToggle
						theme={theme}
						navigationOpen={navigationOpen}
						onClick={handleNavigationToggle}>
						<MenuButton>
							<MenuLine position={"top"} navigationOpen={navigationOpen} />
							<MenuLine position={"middle"} navigationOpen={navigationOpen} />
							<MenuLine position={"bottom"} navigationOpen={navigationOpen} />
						</MenuButton>
					</NavigationToggle>
				</Inner>
			</HeaderContainer>
		</Container>
	);
});

export default Header;
